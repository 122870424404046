<script setup lang="ts">
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import CheckMark from '@/ui/components/CheckMark.vue'
import { useSimpleMessage } from '@/ui/composables'
import { useWorkflowDetails } from '@/workflow-edit'
import { Button, Column, Dropdown, FormItem } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import ExposeWorkflowModal from './ExposeWorkflowModal.vue'

const router = useRouter()
const { showMessage } = useSimpleMessage()
const { workflow, updateWorkflow } = useWorkflowDetails()
const publicLink = computed(() => {
  const route = router.resolve(links.runWorkflow(workflow.value!.id))
  return `${window.location.origin}${route.href}`
})

const openExposeWorkflowModal = ref(false)

async function handleClickExposeWorkflow() {
  if (workflow.value?.isPublic) {
    await updateWorkflow({ isPublic: false })
  } else {
    openExposeWorkflowModal.value = true
  }
}

async function handleCopyLink() {
  if (!publicLink.value) return
  await navigator.clipboard.writeText(publicLink.value)
  showMessage('Link copied to clipboard')
}
</script>
<template>
  <Column gap="s">
    <FormItem label="Access to workflow">
      <CheckMark
        :disabled="!workflow?.draft"
        :model-value="!!workflow?.isPublic"
        @update:model-value="handleClickExposeWorkflow"
      >
        Publicly accessible
      </CheckMark>
    </FormItem>

    <Dropdown v-if="workflow?.isPublic">
      <template #trigger="{ toggle }">
        <Button @click="toggle">
          <TwinIcon icon="Eye" />
          Show URL
        </Button>
      </template>
      <template #content="{ close }">
        <Column>
          <h3>Run workflow URL</h3>
          <div :class="$style.code">
            <pre><code>{{ publicLink }}</code></pre>
          </div>
          <Button color="primary" @click="handleCopyLink().then(close)">
            <TwinIcon icon="Copy" />
            Copy to clipboard
          </Button>
        </Column>
      </template>
    </Dropdown>
  </Column>

  <ExposeWorkflowModal v-model="openExposeWorkflowModal" />
</template>

<style module>
.code {
  background: var(--grey-1-altbg);
  border: 1px solid var(--grey-2-lines);
  border-radius: 8px;
}

.code pre {
  width: 568px;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
</style>
