<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import { useHelpScout } from '@/plugins/useHelpScout'
import { links } from '@/router'
import { DisplayDate, RunStatus, ShowMoreButton, TwinIcon } from '@/ui/components'
import { useEditorLinks } from '@/workflow-edit'
import { Button, Card, Chip, Column, Icon, Row, Spinner, TextInput } from '@madxnl/dodo-ui'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import RunWorkflowModal from '../runworkflow/RunWorkflowModal.vue'
import { useWorkflows, type WorkflowItemFragment } from './useWorkflows'

const { workflows, showMore, createNew, search } = useWorkflows()
const helpscout = useHelpScout()

const { linkWorkflowEdit } = useEditorLinks()
const router = useRouter()
const workflowToRun = ref<WorkflowItemFragment | null>(null)
const showRunModal = ref(false)

const quickstartArticleId = '67adab460f877e0a59fae51f'

function clickRunWorkflow(workflow: WorkflowItemFragment) {
  workflowToRun.value = workflow
  showRunModal.value = true
}

async function clickNewWorkflow() {
  const newWorkflow = await createNew()
  await router.push(linkWorkflowEdit(newWorkflow.id))
}

async function clickViewData(workflowId: string) {
  await router.push(links.workflowData(workflowId))
}

async function clickEdit(workflowId: string) {
  await router.push(linkWorkflowEdit(workflowId))
}
</script>

<template>
  <MainPageLayout>
    <Row justify="between">
      <h1>Workflows</h1>

      <Row>
        <TextInput v-model="search" type="search" placeholder="Search" />

        <Button variant="solid" color="primary" title="New workflow" @click="clickNewWorkflow">
          <TwinIcon icon="Plus" /> New
        </Button>
      </Row>
    </Row>

    <Spinner v-if="workflows == null" />

    <template v-else-if="workflows.length === 0">
      <Column v-if="search">
        <p>No results found for '{{ search }}'</p>
      </Column>
      <Column v-else align="center">
        <br />
        <h3>You have no workflows yet. What are you waiting for?</h3>
        <Button @click="clickNewWorkflow">
          <Icon name="Add" />
          Create your first workflow
        </Button>

        <p>Or take a look at our quickstart guide:</p>
        <Button color="primary" @click="helpscout.show({ article: quickstartArticleId })">
          <TwinIcon icon="Info" />
          Quickstart
        </Button>
      </Column>
    </template>

    <template v-else-if="workflows">
      <Column gap="m">
        <Card
          v-for="workflow in workflows"
          :key="workflow.id"
          :class="[$style.workflowCard, workflow.draft && $style.draft]"
          padding="l"
          gap="s"
          hoverable
          role="button"
          aria-label="Edit workflow"
          tabindex="0"
          @click="() => clickEdit(workflow.id)"
        >
          <Row>
            <Column gap="m" grow>
              <Row gap="m">
                <h2 :class="$style.workflowName">{{ workflow.name }}</h2>
                <Chip v-if="workflow.draft" color="info" variant="solid">Draft</Chip>
              </Row>
              <p v-if="workflow.description" class="form-description">
                {{ workflow.description }}
              </p>
              <Column gap="l">
                <Row gap="xl">
                  <Column gap="s">
                    <p class="form-description">Updated at</p>
                    <DisplayDate :date="Number(workflow.updatedAt)" />
                  </Column>
                  <Column gap="s">
                    <p class="form-description">Created at</p>
                    <DisplayDate :date="Number(workflow.createdAt)" />
                  </Column>
                  <Column gap="s" style="text-align: center">
                    <p class="form-description">Total runs</p>
                    {{ workflow.numberOfRuns }}
                  </Column>
                  <Column v-if="workflow.latestRun" gap="s">
                    <p class="form-description">Latest run on</p>
                    <DisplayDate :date="Number(workflow.latestRun?.createdAt)" />
                  </Column>
                  <Column v-if="workflow.latestRun" gap="s">
                    <p class="form-description">Latest run status</p>
                    <Row>
                      <RunStatus :run="workflow.latestRun" />
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Row grow>
                    <Button
                      variant="solid"
                      color="primary"
                      :disabled="workflow.draft"
                      @click.stop="clickRunWorkflow(workflow)"
                    >
                      <TwinIcon icon="Play" />Run
                    </Button>
                    <Button @click.stop="clickViewData(workflow.id)"> <TwinIcon icon="NPS" /> View data </Button>
                  </Row>
                </Row>
              </Column>
            </Column>
            <Column>
              <TwinIcon :class="$style.chevron" icon="ChevronRight" size="l" />
            </Column>
          </Row>
        </Card>
      </Column>

      <ShowMoreButton :show-more="showMore" variant="clear" />
    </template>
  </MainPageLayout>

  <RunWorkflowModal v-if="workflowToRun" :open="showRunModal" :workflow="workflowToRun" @close="showRunModal = false" />
</template>

<style module>
.draft {
  outline: 3px dashed var(--dodo-color-info);
  outline-offset: -3px;
  box-shadow: none;
}
</style>
