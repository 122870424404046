<script lang="ts" setup>
import { links } from '@/router'
import { InlineMessage } from '@/ui/components'
import { Button, Column, Form, FormItem, TextInput } from '@madxnl/dodo-ui'
import { onMounted, watch } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { LoginSignupTabs } from '.'
import { useAuthSession, useLoginForm, useLoginRedirect } from '../composables'
import AuthPageLayout from './AuthPageLayout.vue'

const { data, submit, errors, loginError } = useLoginForm()
const { postLoginRedirect } = useLoginRedirect()
const { isAuthenticated } = useAuthSession()
const router = useRouter()

onMounted(checkAuthenticated)

watch(isAuthenticated, checkAuthenticated)

async function clickSubmit() {
  const ERROR_NOT_ACTIVATED = '#0402'
  try {
    await submit()
  } catch (err) {
    if (err instanceof Error && err.message.includes(ERROR_NOT_ACTIVATED)) {
      return router.push({ ...links.activationRequired(), query: { email: data.email, source: 'login' } })
    }
    throw err
  }
}

async function checkAuthenticated() {
  if (isAuthenticated.value) await postLoginRedirect()
}
</script>

<template>
  <AuthPageLayout title="My Twin AI">
    <InlineMessage v-if="loginError" color="danger">
      {{ loginError }}
    </InlineMessage>

    <template #tabs>
      <LoginSignupTabs />
    </template>

    <Form @submit="clickSubmit">
      <Column gap="l">
        <FormItem label="Email" for="login-email" :error="errors.email">
          <TextInput id="login-email" v-model="data.email" name="email" type="email" autocomplete="email" />
        </FormItem>

        <FormItem label="Password" for="login-password" :error="errors.password">
          <TextInput id="login-password" v-model="data.password" name="password" type="password" />
        </FormItem>

        <Button variant="solid" color="primary" type="submit">Log in</Button>

        <RouterLink v-slot="{ navigate }" :to="links.forgotPassword()">
          <Button color="primary" variant="link" title="" @click="navigate">Forgot password?</Button>
        </RouterLink>
      </Column>
    </Form>
  </AuthPageLayout>
</template>
