<script setup lang="ts">
import { RunStatus, type RunItemFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { Chip, Row, Spinner, type ColorProp } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'

const props = defineProps<{
  run: RunItemFragment
  prefix?: string
  size?: 's'
}>()

const showModal = ref(false)

const statusColorProp: Record<RunStatus, ColorProp> = {
  [RunStatus.Done]: 'success',
  [RunStatus.Pending]: 'warning',
  [RunStatus.Running]: 'primary',
  [RunStatus.Failed]: 'danger',
}

const label = computed(() => {
  if (props.prefix) return `${props.prefix} ${props.run?.status.toLowerCase()}`
  return props.run?.status
})
</script>

<template>
  <Chip
    :color="statusColorProp[run.status]"
    :class="size ? $style[size] : ''"
    @click.stop.prevent="() => (showModal = true)"
  >
    <Row>
      <template v-if="size !== 's'">
        {{ label }}
      </template>
      <Row v-if="size === 's' || run.status !== RunStatus.Done" :class="$style.icon">
        <TwinIcon v-if="run.status === RunStatus.Done" icon="Check" />
        <TwinIcon v-if="run.status === RunStatus.Failed" icon="Info" />
        <Spinner
          v-if="run.status === RunStatus.Running || run.status === RunStatus.Pending"
          size="small"
          color="inherit"
        />
      </Row>
    </Row>
  </Chip>
  <template v-if="run.status === RunStatus.Failed">
    <LargeModalLayout :open="showModal" title="Run failed" size-xl @close="showModal = false">
      <template #content>
        <code>{{ run.failedReason }}</code>
        <small>
          <code>{{ run.stacktrace }}</code>
        </small>
      </template>
    </LargeModalLayout>
  </template>
</template>

<style module>
.chip {
  flex-shrink: 0;
  cursor: pointer;
}
.s {
  padding: 0;
  height: 24px;
  width: 24px;
  background: color-mix(in lab, var(--chip-color) 15%, var(--dodo-color-background));
  border: 1px solid color-mix(in lab, var(--chip-color) 50%, var(--dodo-color-background));
  box-shadow: var(--card-shadow);
  justify-content: center;
}
.s .icon {
  transform: scale(75%, 75%);
}
</style>
