<script lang="ts" setup>
import { Navbar } from '@/navigation/components'
import { useHelpScout } from '@/plugins/useHelpScout'
import { RunsNavItem } from '@/runs'
import { TwinIcon } from '@/ui/components'
import { CreditsNavItem, UserNavItem } from '@/user'
import { WorkflowsNavItem } from '@/workflows'
import NavbarItem from './NavbarItem.vue'

const helpScout = useHelpScout()

function help() {
  helpScout.show()
}
</script>

<template>
  <Navbar
    :nav-entries="[
      { component: WorkflowsNavItem, position: 'end', order: 0 },
      { component: RunsNavItem, position: 'end', order: 1 },
      { slot: 'help', position: 'end', order: 4 },
      { isSeparator: true, position: 'end', order: 7 },
      { component: CreditsNavItem, position: 'end', order: 14 },
      { component: UserNavItem, position: 'end', order: 16 },
    ]"
  >
    <template #help>
      <NavbarItem :is-active="helpScout.active.value" @click="help()">
        <TwinIcon icon="Question" />
        <!-- <span>Help</span> -->
      </NavbarItem>
    </template>
  </Navbar>
</template>
