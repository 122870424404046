<script setup lang="ts">
import type { BlockConfigDetailsFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useBlockTypes } from '@/workflow-edit/composables'
import { Button, Column, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, toRefs, watch } from 'vue'
import BlockSettingsField from './BlockSettingsField.vue'
import { useManageArguments, useSubworkflowSettings } from './composables'

const props = defineProps<{
  workflow?: WorkflowDetailsFragment
  config?: BlockConfigDetailsFragment
  context: 'workflow-output' | 'settings' | 'prompt'
}>()

const { config, workflow } = toRefs(props)
const { addExtraArgument, canAddExtraArgument, isNameTaken, formatArgName } = useManageArguments({ workflow, config })
const { loadSubworkflow } = useSubworkflowSettings()
const { getBlockType } = useBlockTypes()

const configArgs = computed(() => {
  if (!config?.value) return workflow.value?.result ?? []
  return config?.value?.arguments || []
})

const generatedFields = computed(() => {
  // list of base field names for block config settings or workflow inputs
  const fields: string[][] = []
  const blockTypeArgs = blockType.value?.arguments || []
  const argsSeen = new Set<string>()
  for (const blockTypeArg of blockTypeArgs) {
    if (!blockTypeArg.name) continue
    argsSeen.add(blockTypeArg.name)
    if (props.context === 'prompt') continue
    fields.push([blockTypeArg.name])
  }
  for (const argument of configArgs.value) {
    // Add custom arguments that are not defined in the block type
    if (argsSeen.has(argument.name)) continue
    fields.push([argument.name])
  }
  return fields
})

const blockType = computed(() => getBlockType(config.value?.block))

const subworkflowId = computed(() => props.config?.workflow?.id)
watch(subworkflowId, () => loadSubworkflow(props.config ?? null), { immediate: true })

const newArgName = ref('')

function onInput(e: InputEvent) {
  const el = e.target as HTMLInputElement
  const v = formatArgName(el.value)
  const cursorPos = el.selectionStart != null ? el.selectionStart - el.value.length + v.length : null
  el.value = v
  newArgName.value = v
  if (cursorPos != null) el.setSelectionRange(cursorPos, cursorPos)
}

async function clickAddArgument() {
  const argumentType = BlockConfigArgumentType.Constant
  await addExtraArgument(newArgName.value, argumentType)
  newArgName.value = ''
}

const showAddArgument = computed(() => {
  if (!workflow.value?.draft) return false
  if (!canAddExtraArgument.value) return false
  if (subworkflowId.value) return false // Don't show add argument button for subworkflow settings
  return true
})
</script>

<template>
  <p v-if="!generatedFields.length" class="form-description">
    <template v-if="context === 'workflow-output'">
      Add the expected output data generated by the workflow. After running the workflow, this output will be shown in
      the results of the workflow.
    </template>
    <template v-else>No settings items yet</template>
  </p>

  <Column v-else gap="l">
    <template v-for="(field, i) of generatedFields" :key="i">
      <BlockSettingsField
        :workflow="workflow"
        :config="config"
        :field="field"
        :disabled="workflow && !workflow.draft"
        :block-item="blockType"
      />
    </template>
  </Column>

  <Form v-if="showAddArgument" @submit="clickAddArgument">
    <hr />
    <h5>
      <template v-if="context === 'workflow-output'">Add expected output data</template>
      <template v-else> Add a new settings item </template>
    </h5>
    <FormItem>
      <Row>
        <TextInput v-model="newArgName" style="flex: 1" type="text" placeholder="Enter a name" @input="onInput" />
        <Button :disabled="!newArgName || isNameTaken(newArgName)" type="submit" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>
