<script setup lang="ts">
import { RunStatus } from '@/ui/components'
import { useWorkflowDetails } from './composables'

const { workflow } = useWorkflowDetails()
</script>

<template>
  <div v-if="workflow?.latestRun && workflow.latestRun.status !== 'Done'" :class="$style.editorRunStatus">
    <RunStatus :run="workflow.latestRun" :prefix="workflow.latestRun.status === 'Failed' ? `Latest run` : ''" />
  </div>
</template>

<style module>
.editorRunStatus {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
