<script setup lang="ts">
import type { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { CheckMark, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Form, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useFieldTypeOptions, useWorkflowSettingForm, useWorkflowSettings } from './composables'

const { deleteWorkflowArg } = useWorkflowSettings()
const { getInputFieldType, inputFieldOptions, setFieldType } = useFieldTypeOptions()
const { data, load, errors, submit, selectedType, addNewChoice } = useWorkflowSettingForm()
const { showMessage } = useSimpleMessage()

const props = defineProps<{
  setting: WorkflowInputArgumentFragment
  disabled: boolean
}>()

const isEditing = ref(false)

const type = computed(() => getInputFieldType(props.setting))

const listItemTypeOptions = computed(() => inputFieldOptions.filter((x) => !['array', 'multifile'].includes(x.value)))

function clickEdit() {
  load(props.setting)
  isEditing.value = true
}

async function clickSubmit() {
  const submitted = await submit()
  if (!submitted) return
  isEditing.value = false
  showMessage('Input data field updated')
}
</script>

<template>
  <Column gap="s">
    <Row justify="between">
      <span class="dodo-label-text dodo-nowrap">{{ setting.name }}</span>
      <Row gap="0">
        <Button square size="s" variant="link" :disabled="disabled" @click="clickEdit">
          <TwinIcon size="s" icon="Edit" />
        </Button>
        <Button square size="s" variant="link" :disabled="disabled" @click="deleteWorkflowArg(setting)">
          <TwinIcon size="s" icon="Delete" />
        </Button>
      </Row>
    </Row>

    <FormItem :description="setting.description">
      <Select
        :model-value="type"
        :options="inputFieldOptions.map((x) => x)"
        :disabled="disabled"
        @update:model-value="(type) => setFieldType(setting, type)"
      />
    </FormItem>
  </Column>

  <LargeModalLayout :open="isEditing" title="Edit input field" @close="isEditing = false">
    <template #content>
      <Form @submit="clickSubmit">
        <FormItem label="Name" :error="errors('name')">
          <TextInput v-model="data.name" type="text" placeholder="Choose a name for this input" />
        </FormItem>
        <FormItem label="Description" :error="errors('description')">
          <TextInput v-model="data.description" type="text" placeholder="Describe this input" />
        </FormItem>
        <FormItem label="Input type" :error="errors('type')">
          <Select v-model="data.type" :options="inputFieldOptions" />
        </FormItem>
        <FormItem v-if="data.type === 'array'" label="List item type" :error="errors('arrayType')">
          <Select v-model="data.itemType" :options="listItemTypeOptions" />
        </FormItem>

        <FormItem :error="errors('options')">
          <Column v-if="data.type === 'choice'" gap="m" padding="m">
            <template v-for="(option, i) in data.options" :key="option">
              <Row align="start">
                <Column grow>
                  <FormItem :label="`Choice ${i + 1}`" :error="errors(`options.${i}.value`)">
                    <TextInput v-model="option.value" type="text" placeholder="Enter a choice" />
                  </FormItem>
                </Column>

                <Button
                  :disabled="data.options.length < 2"
                  variant="clear"
                  square
                  title="Remove choice"
                  size="s"
                  @click="data.options.splice(i, 1)"
                >
                  <TwinIcon icon="Cross" size="s" />
                </Button>
              </Row>
            </template>

            <Button variant="clear" color="primary" size="s" @click="addNewChoice">
              <TwinIcon icon="Plus" size="s" />
              New choice
            </Button>
          </Column>
        </FormItem>
        <br />
        <template v-if="selectedType && !selectedType.neverRequired">
          <CheckMark v-model="data.required"> This field is required </CheckMark>
        </template>
      </Form>
    </template>
    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button variant="solid" color="primary" type="submit" @click.prevent="clickSubmit">Save</Button>
    </template>
  </LargeModalLayout>
</template>
