import type { Reactive } from 'vue'
import { computed, reactive, unref, watch } from 'vue'

type Data = Record<string, unknown>
type ReactiveData = Reactive<Data>
type MessageResult = string | undefined | null
type CustomValidator = (value: unknown, key: string, rule: Rule) => Promise<MessageResult> | MessageResult
type Rule = {
  name?: string
  required?: boolean
  maxLength?: number
  minLength?: number
  validators?: CustomValidator[]
}
type GetRules = (addRule: (key: string, rule: Rule) => void) => void

export function useValidation2(data: ReactiveData, getRules: GetRules) {
  const errorDict = reactive<Record<string, string[] | undefined>>({})

  watch(data, revalidateInvalidFields)

  const rules = computed(() => {
    const map = new Map<string, Rule>()
    getRules((key, rule) => map.set(key, rule))
    return map
  })

  async function validateAll(): Promise<boolean> {
    clearErrors()
    return validateFields(...Array.from(rules.value.keys()))
  }

  async function validateFields(...keys: string[]): Promise<boolean> {
    const result = await Promise.all(keys.map((k) => validateField(k)))
    return result.every(Boolean)
  }

  async function validateField(key: string): Promise<boolean> {
    const rule = rules.value.get(key)
    const value = getValue(key)
    const msgs: string[] = []
    if (rule) {
      if (rule.required && !value) msgs.push('is required')
      if (typeof value === 'string' && rule.minLength != null && value.length < rule.minLength)
        msgs.push(`must be at least ${rule.minLength} characters`)
      if (typeof value === 'string' && rule.maxLength != null && value.length > rule.maxLength)
        msgs.push(`must be less than ${rule.maxLength} characters`)
      for (const validator of rule.validators ?? []) {
        const msg = await validator(value, key, rule)
        if (msg) msgs.push(msg)
      }
    }
    const name = rule?.name || readableNameForKey(key)
    const prefiedMsgs = msgs.map((msg) => `${name} ${msg}`)
    const valid = prefiedMsgs.length === 0
    errorDict[key] = valid ? undefined : prefiedMsgs
    return valid
  }

  async function revalidateInvalidFields(): Promise<boolean> {
    const invalidKeys = Object.keys(errorDict).filter(hasError)
    if (!invalidKeys.length) return true
    return validateFields(...invalidKeys)
  }

  function clearErrors() {
    for (const k in errorDict) delete errorDict[k]
  }

  function hasError(key: string) {
    return !!errorDict[key]?.length
  }

  function getValue(key: string) {
    let value: unknown = unref(data)
    const path = key.split('.')
    for (const prop of path) {
      if (value && typeof value === 'object' && prop in value) {
        value = value[prop as keyof typeof value]
      } else {
        throw new Error(`Invalid key: ${key}`)
      }
    }
    return value
  }

  function readableNameForKey(key: string) {
    const name = key
      .split('.')
      .filter((x) => !isFinite(Number(x)))
      .join(' ')
      .replace(/[a-z][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
      .replace(/^./, (match) => match.toUpperCase())
    return name
  }

  function errors(key: string): string | undefined {
    getValue(key)
    return errorDict[key]?.[0]
  }

  function allErrors() {
    const entries: [string, string[]][] = []
    Object.entries(errorDict).forEach(([key, msgs]) => {
      if (msgs) entries.push([key, msgs])
    })
    return entries
  }

  return { errors, allErrors, validateAll, validateFields }
}
