import type {
  WorkflowInputArgumentFragment,
  WorkflowInputArgumentInputSchema,
  WorkflowInputOption,
} from '@/generated/sdk'
import { useValidation2 } from '@/ui/composables'
import { computed, reactive, ref } from 'vue'
import { useFieldTypeOptions, type FieldType } from './useFieldTypeOptions'
import { useWorkflowSettings } from './useWorkflowSettings'

const name = ref('')
const description = ref('')
const required = ref(false)
const type = ref<FieldType | null>(null)
const itemType = ref<FieldType | null>(null)
const enableOptions = ref(false)
const newChoice = () => ({ label: '', value: '', description: '' })
const options = ref<WorkflowInputOption[]>([newChoice()])
const data = reactive({ name, description, type, required, itemType, enableOptions, options })
const currentArg = ref<WorkflowInputArgumentFragment | null>(null)

export function useWorkflowSettingForm() {
  const { getInputFieldType, inputFieldOptions, setFieldType } = useFieldTypeOptions()
  const { workflowInputArguments, saveWorkflowArg } = useWorkflowSettings()

  function nameValidator(name?: unknown) {
    const nameTaken = !!workflowInputArguments?.value.some((a) => a.name === name && a.id !== currentArg.value?.id)
    if (nameTaken) return 'must be unique'
  }

  function requiredIfArray(value?: unknown) {
    if (type.value === 'array' && !value) return 'is required'
    return null
  }

  const selectedType = computed(() => inputFieldOptions.find((o) => o.value === type.value))
  const selectedItemType = computed(() => inputFieldOptions.find((o) => o.value === itemType.value))

  const { errors, validateAll } = useValidation2(
    reactive({
      name: name,
      description: description,
      type: selectedType,
      arrayType: selectedItemType,
      options: options,
    }),
    (rule) => {
      rule('name', { required: true, maxLength: 50, validators: [nameValidator] })
      rule('type', { required: true })
      rule('arrayType', { validators: [requiredIfArray] })
      if (enableOptions.value) {
        options.value.forEach((o, i) => {
          rule(`options.${i}.value`, { required: true, name: 'Choice' })
        })
      }
    },
  )

  function load(setting: WorkflowInputArgumentFragment) {
    // console.log('load', { ...setting })
    currentArg.value = setting
    name.value = setting.name
    description.value = setting.description
    type.value = getInputFieldType(setting)
    required.value = setting.required
    itemType.value = setting.items ? getInputFieldType(setting.items) : null
    enableOptions.value = setting.inputType === 'Select'
    options.value = setting.options?.map((o) => ({ ...o })) || [newChoice()]
  }

  function addNewChoice() {
    data.options.push(newChoice())
  }

  async function submit() {
    const isValid = await validateAll()
    if (!isValid) return false
    const arg = currentArg.value!

    const input: WorkflowInputArgumentInputSchema = {
      id: arg.id,
      description: description.value,
      required: selectedType.value!.neverRequired ? false : data.required,
      name: name.value,
      options: type.value === 'choice' ? options.value.map((o) => ({ ...o, label: o.value })) : null,
    }
    setFieldType(input, selectedType.value!.value, selectedItemType.value?.value)
    await saveWorkflowArg(arg, input)

    return true
  }

  return { data, load, submit, errors, selectedType, addNewChoice }
}
