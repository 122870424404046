import {
  BlockArgumentInputType,
  BlockArgumentType,
  type WorkflowInputArgumentFragment,
  type WorkflowInputArgumentInputSchema,
} from '@/generated/sdk'

export type FieldType = 'checkbox' | 'text' | 'textarea' | 'number' | 'file' | 'multifile' | 'array' | 'choice'

export const inputFieldOptions: { label: string; value: FieldType; neverRequired?: boolean }[] = [
  { label: 'Checkbox', value: 'checkbox', neverRequired: true },
  { label: 'Text', value: 'text' },
  { label: 'Text area', value: 'textarea' },
  { label: 'Number', value: 'number' },
  { label: 'File', value: 'file' },
  { label: 'Multiple files', value: 'multifile' },
  { label: 'List', value: 'array' },
  { label: 'Choice', value: 'choice' },
] as const

function getArgumentAndInputType(fieldType: FieldType) {
  if (fieldType === 'checkbox') {
    return { argumentType: BlockArgumentType.Boolean, inputType: BlockArgumentInputType.Checkbox }
  } else if (fieldType === 'number') {
    return { argumentType: BlockArgumentType.Number, inputType: BlockArgumentInputType.Number }
  } else if (fieldType === 'file') {
    return { argumentType: BlockArgumentType.File, inputType: BlockArgumentInputType.File }
  } else if (fieldType === 'multifile') {
    return { argumentType: BlockArgumentType.Array, inputType: BlockArgumentInputType.Unknown }
  } else if (fieldType === 'array') {
    return { argumentType: BlockArgumentType.Array, inputType: BlockArgumentInputType.Unknown }
  } else if (fieldType === 'textarea') {
    return { argumentType: BlockArgumentType.String, inputType: BlockArgumentInputType.TextArea }
  } else if (fieldType === 'text') {
    return { argumentType: BlockArgumentType.String, inputType: BlockArgumentInputType.Text }
  } else if (fieldType === 'choice') {
    return { argumentType: BlockArgumentType.String, inputType: BlockArgumentInputType.Select }
  } else {
    throw new Error(`Unknown fieldType: ${fieldType}`)
  }
}

export function useFieldTypeOptions() {
  function getInputFieldType(argument: WorkflowInputArgumentFragment) {
    if (argument.inputType === 'Select') return 'choice'
    if (argument.argumentType === 'Boolean') return 'checkbox'
    if (argument.argumentType === 'Number') return 'number'
    if (argument.argumentType === 'File') return 'file'
    if (argument.argumentType === 'Array' && argument.inputType === 'File') return 'multifile'
    if (argument.argumentType === 'Array' && argument.items?.argumentType === 'File') return 'multifile'
    if (argument.argumentType === 'Array') return 'array'
    if (argument.inputType === 'TextArea') return 'textarea'
    if (argument.inputType === 'Text') return 'text'
    return null
  }

  function setFieldType(input: WorkflowInputArgumentInputSchema, fieldType: FieldType | null, listType?: FieldType) {
    const option = inputFieldOptions.find((o) => o.value === fieldType)
    if (!option) return
    const types = getArgumentAndInputType(fieldType ?? 'text')
    input.argumentType = types.argumentType
    input.inputType = types.inputType

    if (fieldType === 'multifile') {
      input.items = {
        id: input.items?.id,
        name: 'File',
        description: '',
        ...getArgumentAndInputType('file'),
      }
    } else if (fieldType === 'array') {
      input.items = {
        id: input.items?.id,
        name: 'List item',
        description: '',
        ...getArgumentAndInputType(listType ?? 'text'),
      }
    } else {
      input.items = undefined
    }
  }

  return {
    getInputFieldType,
    setFieldType,
    inputFieldOptions,
  }
}
