<script setup lang="ts">
import { links } from '@/router'
import { useSimpleMessage } from '@/ui/composables'
import { onBeforeUnmount, ref, watch } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import { useWorkflowDetails } from '.'

const props = defineProps<{
  workflowId: string
}>()

// This provides the workflow details to the children components
const { workflow, loadWorkflow, refreshStatus, isRunning } = useWorkflowDetails()
const { showMessage } = useSimpleMessage()
const router = useRouter()
const pollMs = 3000
const unmounted = ref(false)
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

watch(() => props.workflowId, fetch, { immediate: true })
watch(() => isRunning.value, handlePollingChange, { immediate: true })

onBeforeUnmount(() => (unmounted.value = true))

async function handlePollingChange(isRunning: boolean, wasRunning: boolean | undefined) {
  if (wasRunning && !isRunning) {
    const runFailed = workflow.value?.latestRun?.status === 'Failed'
    if (runFailed) {
      showMessage('Workflow run failed', { type: 'danger' })
    } else {
      showMessage('Workflow run completed', { type: 'success' })
    }
  } else if (isRunning) {
    await pollingLoop()
  }
}

async function pollingLoop() {
  await wait(pollMs)
  while (isRunning.value && !unmounted.value) {
    await refreshStatus()
    await wait(pollMs)
  }
}

async function fetch() {
  await loadWorkflow(props.workflowId)
  // Redirect if not found
  if (!workflow.value) {
    showMessage('The workflow was not found', { type: 'danger' })
    return router.replace(links.workflows())
  }
}
</script>

<template>
  <RouterView />
</template>
