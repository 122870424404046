<script setup lang="ts">
import { type BlockConfigArgumentFragment } from '@/generated/sdk'
import SearchableList from '@/ui/components/SearchableList.vue'
import { useBlockTypes } from '@/workflow-edit/composables'
import { Button, FormItem, Modal, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { type useDataSources } from './composables'

const props = defineProps<{
  disabled: boolean
  dataSources: ReturnType<typeof useDataSources>
  setDatasource: (value: string, path: string) => Promise<void>
  removeDatasource: () => Promise<void>
  configArgument: BlockConfigArgumentFragment | null
}>()

const { availableDataSources, allReferences } = props.dataSources
const { getBlockType } = useBlockTypes()

const open = ref(false)
const path = ref('')
const selectedSource = ref('')

const isReference = computed(() => props.configArgument?.argumentType === 'Reference')

watch(open, triggerOpen, { immediate: true })

function triggerOpen() {
  if (open.value) {
    path.value = props.configArgument?.path ?? ''
    selectedSource.value = props.configArgument?.value ?? ''
  }
}

const groupedOptions = computed(() => {
  const groups = availableDataSources.value
  return groups
    .map((group) => ({
      groupName: group.groupName,
      items: group.references.map((ref) => ({
        name: ref.label,
        id: ref.value,
        currentValue: ref.value === selectedSource.value,
      })),
    }))
    .filter((group) => group.items.length > 0)
})

const currentLabel = computed(() => {
  const savedValue = props.configArgument?.value
  const savedPath = props.configArgument?.path
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === savedValue)
  if (!option?.name) return ''
  return option.name + (savedPath ? ` (${savedPath})` : '')
})

const savedRefIsInvalid = computed(() => {
  const savedValue = props.configArgument?.value
  const found = allReferences.value.some(({ value }) => value && savedValue === value)
  return !found
})

function select(reference: string) {
  const option = allReferences.value.find(({ value }) => value === reference)
  if (!option) return
  selectedSource.value = reference
  const block = getBlockType(option.block)
  path.value = block?.defaultPath ?? ''
}

const referencePathPlaceholder = computed(() => {
  const reference = allReferences.value.find((r) => r.value === selectedSource.value)
  if (!reference?.block) return ''
  const block = getBlockType(reference.block)
  return block?.defaultPath ?? ''
})

async function clickSave() {
  await props.setDatasource(selectedSource.value, path.value)
}
</script>

<template>
  <template v-if="isReference">
    <TextInput
      :key="currentLabel"
      :model-value="currentLabel"
      :readonly="!disabled"
      :disabled="disabled"
      :class="[$style.input, !open && savedRefIsInvalid && $style.errorState]"
      @click="open = true"
    />
    <Row>
      <Button
        v-if="isReference"
        :class="$style.dataSourceBtn"
        variant="link"
        :disabled="disabled"
        @click="props.removeDatasource"
      >
        Disconnect data
      </Button>
    </Row>
  </template>
  <template v-else>
    <Row>
      <Button :class="$style.dataSourceBtn" variant="link" :disabled="disabled" @click="open = true">
        Connect data
      </Button>
    </Row>
  </template>

  <Modal :open="open" @close="open = false">
    <template #default="{ close }">
      <SearchableList header="Select data source" :grouped-options="groupedOptions" @select="select" @close="close" />
      <hr />
      <FormItem label="Data to use" optional description="The path to the data you want to use">
        <TextInput v-model="path" :placeholder="referencePathPlaceholder" :disabled="disabled" />
      </FormItem>
      <br />
      <Row justify="end">
        <Button @click="close"> Cancel </Button>
        <Button variant="solid" color="primary" @click="clickSave().then(close)">
          {{ isReference ? 'Save' : 'Connect' }}
        </Button>
      </Row>
    </template>
  </Modal>
</template>

<style module>
.input {
  width: 100%;
  cursor: pointer;
  background-color: var(--dodo-color-primary-light);
  border-color: var(--dodo-color-primary);
}
.errorState {
  border-color: var(--dodo-color-danger);
  background-color: var(--red-light);
}
.dataSourceBtn {
  color: var(--dodo-color-primary);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 0;
  text-decoration: underline;
}
.dataSourceBtn:hover {
  color: var(--grey-4-disabled);
  text-decoration: none;
}
</style>
