import {
  ActivationRequiredPage,
  EmailVerificationPage,
  ForgotPasswordPage,
  InviteAcceptPage,
  LoginPage,
  LogoutPage,
  RequireAuth,
  SetPasswordPage,
  SignupPage,
} from '@/auth/components'
import { getVar } from '@/environment'
import { PageNotFoundRedirect } from '@/navigation'
import { RunOverviewPage } from '@/runs'
import { OrganizationPage, UserSettingsPage } from '@/user'
import EditorPage from '@/workflow-edit/EditorPage.vue'
import ProvideWorkflow from '@/workflow-edit/ProvideWorkflow.vue'
import { PublicRunWorkflowPage, WorkflowBlockDataPage, WorkflowDataPage, WorkflowOverviewPage } from '@/workflows'
import { createRouter, createWebHistory } from 'vue-router'
import { links } from './links'
import { names } from './names'

export const router = createRouter({
  history: createWebHistory(getVar('BASE_URL')),
  routes: [
    {
      path: '/auth',
      children: [
        { name: names.signup, path: 'signup', component: SignupPage },
        { name: names.login, path: 'login', component: LoginPage },
        { name: names.logout, path: 'logout', component: LogoutPage },
        { name: names.emailVerification, path: 'email-verify', component: EmailVerificationPage },
        { name: names.activationRequired, path: 'activate', component: ActivationRequiredPage },
        { name: names.inviteAccept, path: '/invite/:inviteId', props: true, component: InviteAcceptPage },
        { name: names.forgotPassword, path: 'forgot-password', component: ForgotPasswordPage },
        { name: names.setPassword, path: 'reset-password', component: SetPasswordPage },
      ],
      redirect: links.login(),
    },
    {
      path: '',
      component: RequireAuth,
      children: [
        {
          path: '/user',
          children: [
            { name: names.userSetting, path: 'profile', component: UserSettingsPage },
            { name: names.organization, path: 'organization', component: OrganizationPage },
          ],
          redirect: links.userSetting(),
        },
        // Workflows:
        {
          path: '/workflows',
          children: [
            { name: names.workflows, path: '', component: WorkflowOverviewPage },
            {
              path: ':workflowId/runs',
              props: true,
              component: ProvideWorkflow,
              children: [{ component: WorkflowDataPage, name: names.workflowData, props: true, path: '' }],
            },
          ],
          redirect: links.workflows(),
        },
        // Workflow edit:
        {
          path: '/editor',
          children: [
            {
              path: 'edit/:workflowId',
              props: true,
              component: ProvideWorkflow,
              children: [
                { name: names.workflowEdit, path: '', props: true, component: EditorPage },
                {
                  name: names.reviewData,
                  path: 'review/:workflowBlockId',
                  props: true,
                  component: WorkflowBlockDataPage,
                },
              ],
            },
          ],
          redirect: '/',
        },
        // Runs:
        {
          path: '/runs',
          name: names.runs,
          component: RunOverviewPage,
        },
        // Home
        {
          name: names.home,
          path: '',
          redirect: links.workflows(),
        },
      ],
    },
    // Public Run Workflow:
    {
      path: '/run-workflow/:workflowId',
      props: true,
      component: PublicRunWorkflowPage,
      name: names.runWorkflow,
    },
    // 404:
    { path: '/:pathMatch(.*)*', component: PageNotFoundRedirect },
  ],
})
