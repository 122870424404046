<script lang="ts" setup>
import { EditorBlockIcon } from '@/workflow-edit/block'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { TwinIcon } from '.'
import { useUtils } from '../composables'

const { truncate, fuzzyMatch } = useUtils()

const vFocus = {
  mounted: (el: HTMLInputElement) => el.focus(),
}

const props = defineProps<{
  hideHeader?: boolean
  header?: string
  groupedOptions?: {
    groupName: string | null
    items: { name: string; id: string; currentValue?: boolean; blockType?: string }[]
  }[]
}>()

const emit = defineEmits<{
  select: [id: string]
  close: []
}>()

const selectedOption = ref(-1)
const searchText = ref('')
const listEl = ref<Element>()

watch(searchText, () => (selectedOption.value = -1), { immediate: true })

watch(selectedOption, () => {
  if (!listEl.value) return
  const children = Array.from(listEl.value.children)
  children[selectedOption.value]?.scrollIntoView({ behavior: 'smooth' })
})

const filteredGroupOptions = computed(() => {
  const searchString = searchText.value.trim().toLowerCase()
  const filtered = props.groupedOptions?.map((group) => {
    if (!searchString) return group
    const items = group.items.filter((option) => fuzzyMatch(searchString, option.name))
    return { ...group, items }
  })
  const nonEmptyGroups = filtered?.filter((g) => g.items.length > 0)
  return nonEmptyGroups
})
const filteredOptions = computed(() => filteredGroupOptions.value?.flatMap((group) => group.items) || [])
const selectedItem = computed(() => filteredOptions.value?.[selectedOption.value])

function select(id?: string) {
  if (!id) return
  emit('select', id)
  selectedOption.value = filteredOptions.value.findIndex((option) => option.id === id)
}

function moveDown() {
  selectedOption.value = Math.min(selectedOption.value + 1, filteredOptions.value.length - 1)
}

function moveUp() {
  selectedOption.value = Math.max(selectedOption.value - 1, 0)
}
</script>

<template>
  <Row v-if="!hideHeader" gap="l">
    <Row grow>
      <h3>{{ header }}</h3>
    </Row>
    <Button square variant="clear" @click.stop="$emit('close')">
      <TwinIcon icon="Cross" />
    </Button>
  </Row>

  <input
    v-model="searchText"
    v-focus
    :class="['dodo-formfield', $style.search]"
    type="text"
    placeholder="Type to search"
    name="Search"
    @keydown.enter="select(selectedItem?.id)"
    @keydown.down.prevent="moveDown"
    @keydown.up.prevent="moveUp"
  />

  <div ref="listEl" gap="0" :class="$style.list">
    <Row v-if="filteredGroupOptions?.length === 0" style="width: 100%" justify="center">
      <p class="form-description">Nothing found, try again</p>
    </Row>

    <template v-for="(group, i) in filteredGroupOptions" :key="group.groupName">
      <div v-if="i" :class="$style.separator"><hr /></div>
      <h4 v-if="group.groupName != null" :class="$style.heading">{{ group.groupName }}</h4>

      <template v-for="item in group.items" :key="item.id">
        <div
          :class="[$style.option, item === selectedItem && $style.active, item.currentValue && $style.current]"
          data="option"
          @wheel.stop
          @click="select(item.id)"
        >
          <Row>
            <EditorBlockIcon v-if="item.blockType" :block="item.blockType" />
            <Column style="flex: 1">
              {{ truncate(item.name, 38) }}
            </Column>
            <TwinIcon v-if="item.currentValue" icon="Check" />
          </Row>
        </div>
      </template>
    </template>
  </div>
</template>

<style module>
.list {
  height: 300px;
  min-width: 280px;
  overflow: auto;
  scrollbar-width: thin;
}

.heading {
  padding: 8px 0;
}

.search {
  margin-bottom: 8px;
}

.option {
  cursor: pointer;
  padding: 8px 16px;
  margin-bottom: 2px;
  border-radius: 8px;
}

.option.active,
.option:hover {
  background: var(--dodo-color-primary-light);
}

.option.current {
  background: var(--dodo-color-primary);
  color: var(--grey-0-white);
}

.titleGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  padding: 16px;
}

.input {
  width: 100%;
  cursor: pointer;
  background-color: color-mix(in lab, var(--dodo-color-info) 5%, transparent);
}
</style>
