import { useApiClient, usePagination } from '@/api'
import type { RunDetailsFragment } from '@/generated/sdk'
import { ref } from 'vue'

const runs = ref<RunDetailsFragment[]>()

export function useRuns() {
  const api = useApiClient()

  const { pagination, showMore } = usePagination(runs, refresh)

  async function refresh(filter?: { input: { parentRun: null } }) {
    const response = await api.client.runs({ ...filter, queryArgs: pagination })
    runs.value = response.run
  }

  async function deleteRun(runId: string) {
    await api.client.deleteRun({ runId })
    await refresh()
  }

  return { runs, refresh, deleteRun, showMore }
}
