import { ref } from 'vue'

const BEACON_ID = '33a4222c-111c-45b8-b301-8ff21da07854'

const js = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`

const loaded = ref(false)
const active = ref(false)

function load() {
  if (loaded.value) return
  loaded.value = true
  window.eval(js)
}

export function useHelpScout() {
  load()

  function show(opts?: { search: string } | { article: string }) {
    if (active.value) return
    active.value = true
    window.Beacon('init', BEACON_ID)
    window.Beacon('open')
    window.Beacon('once', 'close', hide)
    if (opts && 'search' in opts) {
      window.Beacon('search', opts.search)
    } else if (opts && 'article' in opts) {
      window.Beacon('article', opts.article)
    }
  }

  function hide() {
    active.value = false
    window.Beacon('destroy')
  }

  return { show, hide, active }
}

declare global {
  interface Window {
    Beacon: (...args: unknown[]) => void
  }
}
