<script lang="ts" setup>
import type { OAuthTokenFragment } from '@/generated/sdk'
import { useOAuthConnections } from '@/user'
import { Select, Spinner } from '@madxnl/dodo-ui'
import { computed, onMounted } from 'vue'

const props = defineProps<{
  modelValue: string | null
  disabled: boolean
  placeholder: string
}>()

const emit = defineEmits<{
  'update:modelValue': [value: string | null]
}>()

const { connections, init } = useOAuthConnections()

onMounted(async () => {
  await init()
})

const oAuthTokenChoices = computed(() => {
  return connections.value?.map((connection) => ({
    label: labelForConnection(connection),
    value: connection.id,
  }))
})

function labelForConnection(connection: OAuthTokenFragment) {
  if (!connection.name) return connection.provider
  return `${connection.provider} (${connection.name})`
}

function change(value: string | null | undefined) {
  if (!value) value = null
  if (value === props.modelValue) return
  emit('update:modelValue', value)
}
</script>

<template>
  <Spinner v-if="connections == null" />
  <p v-else-if="connections.length == 0">No connected apps</p>
  <Select
    v-else
    :model-value="modelValue"
    :options="oAuthTokenChoices ?? []"
    :placeholder="placeholder ?? 'Select a connection'"
    :disabled="disabled"
    @update:model-value="change"
  />
</template>
