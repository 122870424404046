<script setup lang="ts">
import type { BlockConfigDetailsFragment, WorkflowBlockItemFragment } from '@/generated/sdk'
import { BlockConfigArgumentType, WorkflowBlockType } from '@/generated/sdk'
import { RunStatus, TwinIcon } from '@/ui/components'
import { useUtils } from '@/ui/composables'
import { Button, Card, Chip, Column, Row } from '@madxnl/dodo-ui'
import type { ComponentInstance } from 'vue'
import { computed, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '../composables'
import { useDataSources } from '../sidebar-right/block-sidebar/composables'
import EditorBlockConnector from './EditorBlockConnector.vue'
import EditorBlockIcon from './EditorBlockIcon.vue'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment | null
  blockConfig: BlockConfigDetailsFragment
  selected?: boolean
  disabled: boolean
  disableHover?: boolean
  disableOutput?: boolean
  isDataReview?: boolean
}>()

const emit = defineEmits<{
  (e: 'connect', condition?: string | null | undefined): void
}>()

const activateConnectors = ref(false)

const { getNextBlocks, getBlockTypeDetails, getBlockOutputConditions } = useWorkflowEditor()
const { workflow } = useWorkflowDetails()
const { linkSubworkflowEdit } = useEditorLinks()
const { truncate } = useUtils()
const workflowBlock = computed(() => props.workflowBlock)
const { allReferences } = useDataSources({ workflow, workflowBlock })

const blockType = computed(() => getBlockTypeDetails(props.blockConfig.block))

const isSwitch = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.Switch)
const isIfElse = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.IfElse)
const isNormal = computed(() => !isSwitch.value && !isIfElse.value)

const hasMissingRef = computed(() => {
  if (!props.workflowBlock) return false
  const argsWithRefs = props.workflowBlock.blockConfig.arguments.filter(
    (arg) => arg.argumentType === BlockConfigArgumentType.Reference && arg.value != null,
  )
  const refIsMissing = argsWithRefs.some((arg) => allReferences.value.some((ref) => ref.value === arg.value) === false)
  return refIsMissing
})

const nextBlocks = computed(() => (props.workflowBlock ? getNextBlocks(props.workflowBlock) : []))
const outputConditions = computed(() => (props.workflowBlock ? getBlockOutputConditions(props.workflowBlock) : []))

const subworkflowLink = computed(() => {
  if (!workflow.value || !props.blockConfig.workflow) return
  return linkSubworkflowEdit(workflow.value.id, props.blockConfig.workflow.id)
})

type ConnectorInstance = ComponentInstance<typeof EditorBlockConnector>

// We need to expose connector elements so we can draw lines to them
const inputConnector = ref<ConnectorInstance>()
const outputConnector = ref<ConnectorInstance>()
const yesConnector = ref<ConnectorInstance>()
const noConnector = ref<ConnectorInstance>()
const switchConnectors = ref<ConnectorInstance[]>([])

const outputConnectors = computed(() =>
  [outputConnector.value!, ...switchConnectors.value, yesConnector.value!, noConnector.value!].filter(Boolean),
)

defineExpose({ outputConnectors, inputConnector })

function connectPointerup(condition: string | null | undefined) {
  emit('connect', condition)
}

const latestRun = computed(() => {
  if (!workflow.value || !props.workflowBlock) return null
  const childRuns = workflow.value.latestRun?.childRuns ?? []
  const grandchildRuns = childRuns.flatMap((c) => c.childRuns ?? [])
  const allChildRuns = childRuns.concat(grandchildRuns)
  return allChildRuns.find((c) => c.blockConfig.workflowBlock?.some((wb) => wb.id === props.workflowBlock!.id))
})
</script>

<template>
  <Card
    gap="0"
    padding="0"
    :active="selected"
    :class="[$style.card, isSwitch && $style.wideCard, hasMissingRef && !selected && $style.errorState]"
    :hoverable="!disableHover"
    @mouseover="activateConnectors = true"
    @mouseleave="activateConnectors = false"
    @pointerup="connectPointerup(null)"
  >
    <div :class="$style.input">
      <EditorBlockConnector
        ref="inputConnector"
        :disabled="disabled"
        :workflow-block="workflowBlock"
        :is-data-review="isDataReview"
        :hover-state="activateConnectors"
        is-input
      />
    </div>

    <div :class="[isSwitch && $style.switchLayout]">
      <Column padding="m">
        <Row gap="m">
          <EditorBlockIcon :block="blockConfig.block" />
          <Column gap="xs" grow>
            <h4 class="overflow-ellipsis">
              {{ truncate(workflowBlock?.name ?? 'Untitled block', 38) }}
            </h4>
            <span class="form-description">{{ blockType?.readableName }}</span>
          </Column>
        </Row>

        <Row v-if="subworkflowLink" justify="end">
          <RouterLink v-slot="{ navigate }" :to="subworkflowLink">
            <Button variant="link" color="primary" @click="navigate">
              Open
              <TwinIcon icon="LinkExternal" />
            </Button>
          </RouterLink>
        </Row>
      </Column>

      <div v-if="isSwitch" :class="$style.switch">
        <Row v-for="condition in outputConditions" :key="condition || 'output'" justify="end" gap="m">
          <Row padding="s">
            <p>{{ JSON.stringify(condition) }}</p>
          </Row>
          <EditorBlockConnector
            ref="switchConnectors"
            :condition="condition || undefined"
            :disabled="disabled"
            :workflow-block="workflowBlock"
            is-switch
            :is-data-review="isDataReview"
            :hover-state="activateConnectors"
            @pointerup="connectPointerup(condition)"
          />
        </Row>
      </div>
    </div>

    <div v-if="isIfElse" :class="$style.yesNo">
      <div :class="$style.yesNoCol">
        <Chip color="success" :class="[nextBlocks?.every((x) => x?.condition !== 'true') && $style.chipDisabled]">
          Yes
        </Chip>

        <div :class="$style.yesNoLine"></div>

        <EditorBlockConnector
          ref="yesConnector"
          condition="true"
          :disabled="disabled"
          :workflow-block="workflowBlock"
          :is-data-review="isDataReview"
          :hover-state="activateConnectors"
          @pointerup="connectPointerup('true')"
        />
      </div>
      <div :class="$style.yesNoCol">
        <Chip color="danger" :class="[nextBlocks?.every((x) => x?.condition !== 'false') && $style.chipDisabled]">
          No
        </Chip>

        <div :class="$style.yesNoLine"></div>

        <EditorBlockConnector
          ref="noConnector"
          condition="false"
          :disabled="disabled"
          :workflow-block="workflowBlock"
          :is-data-review="isDataReview"
          :hover-state="activateConnectors"
          @pointerup="connectPointerup('false')"
        />
      </div>
    </div>

    <div v-if="isNormal" :class="$style.output">
      <EditorBlockConnector
        ref="outputConnector"
        :disabled="disabled || !!disableOutput"
        :workflow-block="workflowBlock"
        :is-data-review="isDataReview"
        :hover-state="activateConnectors"
      />
    </div>

    <div v-if="latestRun" :class="$style.runStatus">
      <RunStatus :run="latestRun" size="s" />
    </div>
  </Card>
</template>

<style module>
.card {
  width: 256px;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}
.wideCard {
  width: 512px;
}
.switchLayout {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0;
  align-items: start;
}
.switch {
  border-left: 1px solid var(--grey-2-lines);
}
.switch > :not(:last-child) {
  border-bottom: 1px solid var(--grey-2-lines);
}
.chipDisabled {
  opacity: 0.5;
}
.blockError {
  font-size: var(--dodo-font-small);
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--dodo-color-danger-light);
  color: var(--dodo-color-danger);
}
.errorState {
  outline-color: var(--dodo-color-danger);
}
.yesNo {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.yesNoCol {
  position: relative;
  display: grid;
  place-items: center;
}
.yesNoLine {
  content: '';
  display: block;
  width: 3px;
  height: 16px;
  background: rgba(0, 0, 0, 0.2);
}
.output,
.input {
  display: flex;
  justify-content: center;
}
.runStatus {
  position: absolute;
  bottom: -10px;
  right: -10px;
}
</style>
