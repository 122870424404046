export const names = {
  home: 'home',

  // Auth:
  signup: 'signup',
  login: 'login',
  logout: 'logout',
  emailVerification: 'emailVerification',
  activationRequired: 'activationRequired',
  inviteAccept: 'inviteAccept',
  forgotPassword: 'forgotPassword',
  setPassword: 'setPassword',

  // User:
  userSetting: 'userSetting',
  organization: 'organizationSettings',

  // Workflows:
  workflows: 'workflowsRoute',
  workflowData: 'workflowDataRoute',
  runWorkflow: 'runWorkflowRoute',

  // Workflow edit:
  workflowEdit: 'workflowEditRoute',
  reviewData: 'reviewBlockData',

  // Runs:
  runs: 'runsRoute',
  runDetail: 'runDetailRoute',
}
