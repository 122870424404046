<script setup lang="ts">
import {
  BlockArgumentInputType,
  BlockArgumentType,
  BlockConfigArgumentType,
  type BlockArgumentDetailsFragment,
  type BlockArgumentFragment,
  type BlockConfigFragment,
  type BlockItemFragment,
  type WorkflowDetailsFragment,
  type WorkflowInputArgumentFragment,
  type WorkflowInputArgumentItemFragment,
} from '@/generated/sdk'
import { TypedInput } from '@/ui/components'
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { computed, onMounted, ref, toRefs } from 'vue'
import ChooseDatasource from './ChooseDatasource.vue'
import { useDataSources, useManageArguments } from './composables'

const props = defineProps<{
  field: string[]
  workflow?: WorkflowDetailsFragment
  config?: BlockConfigFragment
  workflowInputArg?: WorkflowInputArgumentFragment | WorkflowInputArgumentItemFragment
  blockItem?: BlockItemFragment
  inputData?: Record<string, unknown>
  onSwapItemOrder?: (direction: number) => void
  enableSwapItemOrder?: (direction: number) => boolean
  deleteFromArray?: () => Promise<void>
  setChildValue?: (value: unknown) => Promise<void>
}>()
const { config, workflow } = toRefs(props)

// Description field overflow check
const description = ref<HTMLElement>()
const isFullDescription = ref(false)
const hasOverflow = (el: HTMLElement) => el.scrollWidth > el.clientWidth

onMounted(() => {
  if (!description.value) return
  isFullDescription.value = !hasOverflow(description.value)
})

const { saveArgument, deleteArgument } = useManageArguments({ config, workflow })

const workflowBlock = computed(() => {
  if (!config.value) return null
  return workflow.value?.workflowBlocks?.find((b) => {
    if (b.blockConfig.id === config.value?.id) return true
    if (b.blockConfig.loop?.blockConfig.id === config.value?.id) return true
    return false
  })
})
const dataSources = useDataSources({ workflow, workflowBlock })

const placeholder = computed(() => {
  return String(getDefaultOf(typeArgument.value) ?? '')
})

const inputType = computed(() => typeArgument.value?.inputType ?? BlockArgumentInputType.Text)
const isReference = computed(() => configArgument.value?.argumentType === 'Reference')
const isArgTypeArray = computed(() => typeArgument.value?.argumentType === 'Array')

const isMultipleFiles = computed(() => {
  const arg = typeArgument.value
  return arg?.argumentType === 'Array' && 'items' in arg && arg?.items?.argumentType === 'File'
})

const isArrayEntry = computed(() => {
  // makes file input accept multiple file selection
  return !!props.onSwapItemOrder
})

const rootName = computed(() => props.field[0]!)
const isArrayItem = computed(() => props.onSwapItemOrder != null)

const disabled = computed(() => {
  if (props.inputData) return false // Run data
  return !workflow.value?.draft
})

const label = computed(() => {
  const lastKey = props.field[props.field.length - 1]!
  const index = Number(lastKey)
  if (isArrayItem.value && Number.isInteger(index)) return `Item ${index + 1}`
  return lastKey
})

const configArgument = computed(() => {
  const argName = props.field[0]!
  if (props.inputData) return null
  if (props.config) return props.config.arguments.find((a) => a.name === argName) ?? null
  return props.workflow?.result?.find((a) => a.name === argName) ?? null
})

type FieldMetaType =
  | BlockArgumentFragment
  | BlockArgumentDetailsFragment
  | WorkflowInputArgumentFragment
  | WorkflowInputArgumentItemFragment

const rootTypeDesc = computed<FieldMetaType | null>(() => {
  if (props.workflowInputArg) {
    return props.workflowInputArg
  }
  const blockTypeArg = props.blockItem?.arguments.find((a) => a.name === rootName.value)
  return blockTypeArg ?? null
})

const typeArgument = computed<FieldMetaType | null>(() => {
  // blockTypeArgument.value
  let arg = rootTypeDesc.value
  for (const key of props.field.slice(1)) {
    if (arg == null) return null
    if (arg.argumentType === BlockArgumentType.Array) {
      arg = ('items' in arg ? arg.items : null) ?? null
    } else if (arg.argumentType === BlockArgumentType.Object) {
      arg = ('properties' in arg ? arg.properties?.find((p) => p.name === key) : null) ?? null
    }
  }
  return arg
})

const getRootValue = computed<unknown>(() => {
  if (props.inputData && props.inputData[rootName.value] != null) {
    // custom run data
    return props.inputData[rootName.value]
  } else if (configArgument.value) {
    return configArgument.value.value
  } else if (rootTypeDesc.value) {
    return getDefaultOf(rootTypeDesc.value)
  }
  return null
})

const modelValue = computed(() => {
  const rootValue = getRootValue.value
  if (configArgument.value?.argumentType === 'Reference') {
    return rootValue
  }
  const parsed = parseJson(String(rootValue))
  const typeArray = rootTypeDesc.value?.argumentType === 'Array'
  const typeObject = rootTypeDesc.value?.argumentType === 'Object'
  const isJson = (typeArray || typeObject) && typeof rootValue === 'string'
  const value = isJson ? parsed : rootValue

  let innervalue = value
  for (const key of props.field.slice(1)) {
    if (Array.isArray(innervalue)) {
      const index = Number(key)
      innervalue = innervalue[index]
    } else if (innervalue && typeof innervalue === 'object') {
      innervalue = (innervalue as Record<string, unknown>)[key]
    }
  }
  const convertedValue = forceType(innervalue, typeArgument.value)
  return convertedValue
})

const createArrayItem = computed(() => {
  const blockTypeArg = typeArgument.value
  if (blockTypeArg?.argumentType !== BlockArgumentType.Array) return
  return async () => {
    const arr = currentArray.value ?? []
    const items = blockTypeArg && 'items' in blockTypeArg ? blockTypeArg.items : null
    const newItem = getDefaultOf(items ?? null)
    const newArray = [...arr, newItem]
    if (newArray.length <= 1) newArray.push(newItem) // Handle add to empty array with 1 shown item
    await setValue(newArray)
  }
})

function setArrayItem(i: number) {
  return async (value: unknown) => {
    const newData = [...(currentArray.value ?? [])]
    const blockTypeArg = typeArgument.value
    const items = blockTypeArg && 'items' in blockTypeArg ? blockTypeArg.items : null
    const isMultipleValues = Array.isArray(value) && items?.argumentType !== 'Array'
    newData.splice(i, 1, ...(isMultipleValues ? value : [value]))
    await setValue(newData)
  }
}

const currentArray = computed(() => {
  const data = modelValue.value
  if (Array.isArray(data)) return data
  return null
})

const arraySubfields = computed(() => {
  const namePath = props.field
  const blockTypeArg = typeArgument.value
  const isArray = blockTypeArg?.argumentType === BlockArgumentType.Array
  if (isArray && 'items' in blockTypeArg && blockTypeArg.items) {
    const subfields: string[][] = []
    const arr = currentArray.value
    for (let i = 0; arr && i < arr.length; i++) {
      const subPath = [...namePath, String(i)]
      subfields.push(subPath)
    }
    // Display at least the first field
    if (subfields.length === 0) {
      subfields.push([...namePath, '0'])
    }
    return subfields
  }
  return null
})

const objectSubfields = computed(() => {
  const namePath = props.field
  const blockTypeArg = typeArgument.value
  const isObject = blockTypeArg?.argumentType === BlockArgumentType.Object
  if (isObject && 'properties' in blockTypeArg && blockTypeArg.properties) {
    const subfields: string[][] = []
    for (const property of blockTypeArg.properties) {
      if (!property.name) continue
      const subPath = [...namePath, property.name]
      subfields.push(subPath)
    }
    return subfields
  }
  return null
})

function itemInputArg() {
  const workflowInputArg = props.workflowInputArg
  if (workflowInputArg && 'items' in workflowInputArg) {
    return workflowInputArg.items
  }
}

function propertyInputArg(index: number) {
  const workflowInputArg = props.workflowInputArg
  const properties = workflowInputArg && 'properties' in workflowInputArg ? workflowInputArg.properties : null
  return properties?.[index] ?? null
}

const choices = computed(() => {
  const arg = typeArgument.value
  const isSelect = typeArgument.value?.inputType === BlockArgumentInputType.Select
  if (arg && 'options' in arg && arg.options && isSelect) {
    return arg.options.map((option) => ({
      value: option.value as string,
      label: option.label,
    }))
  }
  return null
})

const deleteField = computed(() => {
  // Delete array item
  if (props.deleteFromArray) {
    return props.deleteFromArray
  }
  // Custom input
  if (props.field.length === 1 && props.inputData && !typeArgument.value) {
    const key = rootName.value
    return async () => {
      if (props.inputData) {
        delete props.inputData[key]
      }
    }
  }
  // Delete user-added argument
  const arg = configArgument.value
  if (props.field.length === 1 && arg && !typeArgument.value) {
    return async () => {
      await deleteArgument(arg.id)
    }
  }
  return null
})

const canMakeReference = computed(() => {
  if (props.inputData) return false // Custom Run data
  const isRootField = props.field.length === 1
  return isRootField
})

const collapsable = computed(() => {
  if (props.field.length > 1) return false
  if (isMultipleFiles.value) return false
  const type = typeArgument.value?.argumentType
  return type === 'Object' || type === 'Array'
})

const collapsed = ref(arraySubfields.value != null && arraySubfields.value.length > 2)

function forceType(value: unknown, type: FieldMetaType | null) {
  const argumentType = type?.argumentType
  if (argumentType === 'Array') {
    return Array.isArray(value) ? value : [null]
  } else if (argumentType === 'Object') {
    return value && typeof value === 'object' ? value : {}
  } else if (argumentType === 'Boolean') {
    if (typeof value === 'string') return value === 'true'
    else if (typeof value === 'boolean') return value
    else return false
  } else if (argumentType === 'Number') {
    return value != null && isFinite(Number(value)) ? String(value) : ''
  } else if (argumentType === 'Integer') {
    return value != null && Number.isInteger(Number(value)) ? String(value) : ''
  } else if (argumentType === 'File') {
    return typeof value === 'string' && value !== 'null' ? String(value) : ''
  } else if (argumentType === 'String') {
    return typeof value === 'string' ? String(value) : ''
  } else {
    // Unknown
    return typeof value === 'string' ? String(value) : ''
  }
}

async function setValue(value: unknown) {
  if (props.setChildValue) {
    await props.setChildValue(value)
    return
  }
  if (props.field.length === 1) {
    // Set root value
    const type = typeArgument.value?.argumentType
    if (type != 'Array' && Array.isArray(value)) {
      value = value.length > 0 ? value[0] : null
    }
    if (props.inputData) {
      // Run data
      props.inputData[rootName.value] = value
    } else if (value == null) {
      await updateCurrentArgument({ value: undefined })
    } else {
      const shouldStringify = type === 'Boolean' || type === 'Object' || type === 'Array'
      const stringValue = shouldStringify ? JSON.stringify(value) : String(value)
      await updateCurrentArgument({ value: stringValue })
    }
    return
  }
  throw new Error('setChildValue undefined for subfield')
}

function setObjectItem(subfield: string[]) {
  if (typeArgument.value?.argumentType !== BlockArgumentType.Object) throw new Error('Invalid object')
  const key = subfield[subfield.length - 1]!
  return async (value: unknown) => {
    const obj = modelValue.value as Record<string, unknown>
    const newValue = { ...obj, [key]: value }
    await setValue(newValue)
  }
}

function deleteArrayItem(index: number) {
  if (currentArray.value && currentArray.value.length <= 1) {
    return undefined // Cannot delete last item
  }
  return async function deleteItem() {
    const newData = [...(currentArray.value ?? [])]
    newData.splice(index, 1)
    await setValue(newData)
  }
}

async function updateCurrentArgument(input: {
  argumentType?: BlockConfigArgumentType
  value?: string
  path?: string | null
}) {
  await saveArgument(rootName.value, configArgument.value, input)
}

function parseJson(data: undefined | string | null) {
  try {
    return data ? (JSON.parse(data) as unknown) : null
  } catch (e) {
    return null
  }
}

function getDefaultOf(meta: FieldMetaType | null) {
  if (!meta) return null
  if ('defaultValue' in meta) return meta.defaultValue
  return null
}

async function setDatasource(value: string, pathString: string) {
  const argumentType = BlockConfigArgumentType.Reference
  const path = pathString || null
  await updateCurrentArgument({ argumentType, value, path })
}

async function disconnectDatasource() {
  const value = isArgTypeArray.value ? '[]' : ''
  const argumentType = BlockConfigArgumentType.Constant
  await updateCurrentArgument({ argumentType, value: value, path: null })
}

async function swapItemOrder(index: number, direction: number) {
  const newData = [...(currentArray.value ?? [])]
  const temp = newData[index]
  const newIndex = index + direction
  if (newIndex < 0 || newIndex >= newData.length) return
  newData[index] = newData[newIndex]
  newData[newIndex] = temp
  await setValue(newData)
}
</script>

<template>
  <Column gap="s">
    <Row
      justify="between"
      :class="collapsable ? $style.collapsableLabel : ''"
      :aria-label="collapsable ? 'Toggle content' : undefined"
      @click="collapsed = !collapsed"
    >
      <Row gap="xs">
        <TwinIcon v-if="collapsable" :icon="collapsed ? 'ChevronRight' : 'ChevronDown'" size="s" />
        <span class="dodo-label-text">{{ label }}</span>
      </Row>

      <Row gap="0" :class="$style.labelButtons">
        <Button
          v-if="isArgTypeArray && !isReference"
          :disabled="disabled"
          color="primary"
          size="s"
          variant="link"
          style="padding: 0"
          @click.stop="createArrayItem"
        >
          <TwinIcon icon="Plus" size="s" /> Add item
        </Button>

        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          color="primary"
          variant="link"
          :disabled="disabled || enableSwapItemOrder?.(1) === false"
          title="Move down"
          @click.stop="onSwapItemOrder(1)"
        >
          <TwinIcon icon="ChevronDown" size="s" />
        </Button>

        <Button
          v-if="onSwapItemOrder"
          square
          size="s"
          color="primary"
          variant="link"
          :disabled="disabled || enableSwapItemOrder?.(-1) === false"
          title="Move up"
          @click.stop="onSwapItemOrder(-1)"
        >
          <TwinIcon icon="ChevronUp" size="s" />
        </Button>

        <Button
          v-if="deleteField"
          :disabled="disabled"
          square
          size="s"
          variant="link"
          title="Delete item"
          @click.stop.prevent="deleteField"
        >
          <TwinIcon icon="Delete" size="s" />
        </Button>
      </Row>
    </Row>

    <p
      v-if="typeArgument?.description"
      ref="description"
      class="form-description dodo-nowrap"
      :class="[isFullDescription ? $style.descriptionFull : $style.descriptionHasOverflow]"
      @click="isFullDescription = !isFullDescription"
    >
      {{ typeArgument.description }}
    </p>

    <Column v-if="!collapsable || !collapsed" gap="s">
      <template v-if="!isReference">
        <Column v-if="arraySubfields" gap="s" :class="$style.nestedContent">
          <template v-for="(subfield, i) of arraySubfields" :key="String(subfield)">
            <BlockSettingsField
              :workflow="workflow"
              :config="config"
              :field="subfield"
              :workflow-input-arg="itemInputArg() ?? undefined"
              :block-item="props.blockItem"
              :on-swap-item-order="(dir: number) => swapItemOrder(i, dir)"
              :enable-swap-item-order="(dir: number) => i + dir >= 0 && i + dir < arraySubfields!.length"
              :input-data="inputData"
              :disabled="disabled"
              :delete-from-array="deleteArrayItem(i)"
              :set-child-value="setArrayItem(i)"
            />
          </template>
        </Column>

        <Column v-else-if="objectSubfields" gap="s" :class="$style.nestedContent">
          <BlockSettingsField
            v-for="(subfield, i) of objectSubfields"
            :key="String(subfield)"
            :workflow="workflow"
            :config="config"
            :field="subfield"
            :workflow-input-arg="propertyInputArg(i) ?? undefined"
            :block-item="props.blockItem"
            :input-data="inputData"
            :disabled="disabled"
            :set-child-value="setObjectItem(subfield)"
          />
        </Column>

        <TypedInput
          v-else
          :multiple="isArrayEntry"
          :name="label"
          :field="field"
          :config="config"
          :workflow="workflow"
          :model-value="modelValue"
          :disabled="disabled"
          :choices="choices"
          :input-type="inputType"
          :placeholder="placeholder"
          :disable-prompt-editor="inputData != null"
          @update:model-value="setValue"
        />
      </template>

      <ChooseDatasource
        v-if="canMakeReference"
        :disabled="disabled"
        :data-sources="dataSources"
        :set-datasource="setDatasource"
        :remove-datasource="disconnectDatasource"
        :config-argument="configArgument"
      />
    </Column>
  </Column>
</template>

<style module>
.collapsableLabel {
  cursor: pointer;
  height: 32px;
  user-select: none;
}
.collapsableLabel:hover {
  color: var(--dodo-color-primary);
}
.nestedContent {
  padding-left: 8px;
}
.descriptionHasOverflow:hover {
  cursor: pointer;
  text-decoration: underline;
}
.descriptionFull {
  pointer-events: none;
  user-select: none;
  cursor: default;
  white-space: normal;
}
</style>
