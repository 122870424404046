import { useApiClient } from '@/api'
import { type WorkflowDetailsFragment } from '@/generated/sdk'
import { computed, ref } from 'vue'

export const workflow = ref<WorkflowDetailsFragment>()

export function useWorkflowDetails() {
  const { client } = useApiClient()

  async function loadWorkflow(workflowId: string) {
    if (workflowId === workflow.value?.id) return
    workflow.value = undefined
    const response = await client.workflowDetails({ id: workflowId })
    workflow.value = response.workflow[0]
  }

  async function updateWorkflow(update: {
    name?: string
    isPublic?: boolean
    cronSchedule?: string | null
    enableSchedule?: boolean
    description?: string
    editorState?: Record<string, unknown>
  }) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    Object.assign(workflow.value, update)
    const { updateWorkflow } = await client.updateWorkflow({ input: { ...update, id: workflow.value.id } })
    return updateWorkflow
  }

  async function deleteWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    const workflowId = workflow.value.id
    workflow.value = undefined
    await client.deleteWorkflow({ workflowId })
  }

  async function cloneWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    const result = await client.cloneWorkflow({ workflowId: workflow.value.id })
    return result.cloneWorkflow
  }

  async function refreshStatus() {
    if (!workflow.value) return
    const res = await client.workflowStatus({ id: workflow.value.id })
    const polledData = res.workflow[0]
    if (!polledData) return
    workflow.value.latestRun = polledData.latestRun
  }

  const isRunning = computed(() => {
    const workflowStatus = workflow.value?.latestRun?.status
    const blockStatuses = workflow.value?.workflowBlocks?.map((w) => w.latestRun?.status) ?? []
    const allStatuses = blockStatuses.concat(workflowStatus)
    return allStatuses.some((status) => status === 'Pending' || status === 'Running')
  })

  return {
    workflow,
    loadWorkflow,
    updateWorkflow,
    deleteWorkflow,
    cloneWorkflow,
    refreshStatus,
    isRunning,
  }
}
