import { hotjarPlugin, sentryPlugin } from '@/plugins'
import { router } from '@/router/router'
import { crashPlugin } from '@madxnl/dodo-ui'
import 'reflect-metadata'
import { createApp } from 'vue'
import App from './App.vue'

export function renderApp() {
  const app = createApp(App)

  const crash = crashPlugin({
    router,
    onError: (error) => {
      // Ignore ResizeObserver error caused by LastPass
      const resizeObserverError = 'ResizeObserver loop completed with undelivered notifications'
      if (error?.toString().includes(resizeObserverError)) {
        console.warn(`Ignored error: ${resizeObserverError}`) // eslint-disable-line no-console
        return false
      }
    },
  })

  app.use(sentryPlugin)
  app.use(hotjarPlugin)
  app.use(crash)
  app.use(router)

  app.mount('#app')
}
