<script setup lang="ts">
import { useApiClient } from '@/api'
import { BlockConfigArgumentType, type WorkflowBlockItemFragment } from '@/generated/sdk'
import type { NavEntry } from '@/navigation/components'
import { MainPageLayout, Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal } from '@/ui/composables'
import { EditorBlockCard } from '@/workflow-edit'
import { useBlockTypes, useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '@/workflow-edit/composables'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar/'
import RunBlockConfigModal from '@/workflows/runblockconfig/RunBlockConfigModal.vue'
import { Button, Column, Dropdown, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import RunRating from './RunRating.vue'

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'back' },
  { position: 'end', slot: 'run' },
]

const { client } = useApiClient()
const router = useRouter()
const editorLinks = useEditorLinks()
const { workflow } = useWorkflowDetails()
const { selectedBlock, getBlock } = useWorkflowEditor()
const { showRunBlockModal } = useOpenRunBlockModal()
const blockOutput = useBlockOutput()
const { getBlockType } = useBlockTypes()

const props = defineProps<{
  workflowId: string
  workflowBlockId: string
}>()

const workflowBlock = ref<WorkflowBlockItemFragment>()
const workflowBlockArguments = computed(() => workflowBlock.value?.blockConfig.arguments ?? [])

const defaultTypeArguments = computed(() => getBlockType(workflowBlock.value?.blockConfig.block)?.arguments ?? [])

watch(props, fetch, { immediate: true })

const workflowBlockArgumentsWithDefaults = computed(() => {
  const defaultArgs = defaultTypeArguments.value.map((defaultArg) => {
    const workflowBlockArg = workflowBlockArguments.value.find((a) => a.name === defaultArg.name)
    return {
      ...workflowBlockArg,
      name: workflowBlockArg?.name ?? defaultArg.name ?? '',
      value: workflowBlockArg?.value ?? defaultArg.defaultValue,
      isDefault: !workflowBlockArg?.value,
    }
  })
  const dynamicArgs = workflowBlockArguments.value.filter(
    (arg) => !defaultTypeArguments.value.find((defArg) => defArg.name === arg.name),
  )
  const convertedDynamicArgs = dynamicArgs.map((a) => ({
    ...a,
    isDefault: false,
  }))
  return [...defaultArgs, ...convertedDynamicArgs]
})

async function fetch() {
  const res = await client.getWorkflowBlock({ id: props.workflowBlockId })
  workflowBlock.value = res.workflowBlock[0]
  if (!workflowBlock.value) return
  await blockOutput.fetchOutput(workflowBlock.value.blockConfig.id)
}

async function handleReturn() {
  await router.push(editorLinks.linkBackToWorkflowEdit(props.workflowId))
}

async function handleEditBlock() {
  selectedBlock.value = getBlock(props.workflowBlockId)!
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template #navbar>
      <Navbar :nav-entries="navEntries">
        <template #back>
          <Button variant="link" color="primary" @click="handleReturn">
            <TwinIcon icon="ChevronLeft" />
            Edit workflow
          </Button>
        </template>

        <template #run>
          <Row gap="xs">
            <Button variant="solid" color="primary" @click="showRunBlockModal">
              <TwinIcon icon="Play" />
              Run block
            </Button>
          </Row>
        </template>
      </Navbar>
    </template>

    <Column gap="xl">
      <Column>
        <h1>Results of block: {{ workflowBlock?.name }}</h1>
        <Column>
          <p class="form-description">
            If the block's output data looks wrong, review the block settings and the input data. <br />
            Run the block again with modified input data to find out whether this improves the output.
          </p>
        </Column>
      </Column>

      <Row gap="xl" align="start">
        <Column gap="l" style="flex: 0 0 256px">
          <h3>Block</h3>

          <EditorBlockCard
            v-if="workflowBlock"
            :workflow-block="workflowBlock"
            :block-config="workflowBlock.blockConfig"
            :disabled="false"
            selected
            is-data-review
          />
        </Column>

        <Column gap="l" grow>
          <Row justify="between">
            <h3>Block input</h3>

            <Button variant="link" color="primary" style="margin-right: 0" @click="handleEditBlock">
              Edit block
            </Button>
          </Row>

          <template v-if="workflowBlock">
            <table :class="$style.table">
              <tr v-for="(arg, i) in workflowBlockArgumentsWithDefaults" :key="i">
                <th style="width: 256px">{{ arg.name }}</th>
                <td>
                  <Row justify="between">
                    <Column>
                      <em v-if="!arg.value" :class="$style.textMuted">empty</em>
                      <em
                        v-else-if="arg.argumentType === BlockConfigArgumentType.Reference || arg.isDefault"
                        :class="$style.textMuted"
                      >
                        {{ arg.value }} {{ arg.isDefault ? '( default )' : '' }}
                      </em>
                      <span v-else>{{ arg.value }}</span>
                    </Column>
                    <Column>
                      <em v-if="arg.argumentType === BlockConfigArgumentType.Reference">Datasource connected</em>
                      <span v-else>Value</span>
                    </Column>
                  </Row>
                </td>
              </tr>
            </table>
          </template>
        </Column>
      </Row>

      <Column>
        <Row justify="between">
          <h3>Block output & input data</h3>
          <Row>
            <TextInput v-model="blockOutput.search.value" type="search" placeholder="Search" />

            <Dropdown>
              <template #trigger="{ toggle }">
                <Button variant="solid" color="primary" @click="toggle">
                  <TwinIcon icon="Filter" />
                  Filter
                </Button>
              </template>
              <template #content>
                <FormItem label="Filter by Status">
                  <Select v-model="blockOutput.filterStatus.value" :options="blockOutput.statusOptions" />
                </FormItem>

                <FormItem label="Filter by rating">
                  <RunRating
                    :rating="blockOutput.filterRating.value"
                    @update-rating="blockOutput.handleFilterRatingChange"
                  />
                </FormItem>
                <hr />
                <Button variant="clear" @click="blockOutput.resetFilters">Reset filters</Button>
              </template>
            </Dropdown>
          </Row>
        </Row>

        <DataPageTable
          v-if="workflowBlock"
          :block-config="workflowBlock.blockConfig"
          :block-output="blockOutput"
          :input-arguments="workflowBlockArgumentsWithDefaults"
          :default-type-arguments="defaultTypeArguments"
        />
      </Column>
    </Column>

    <template #footer>
      <DataActionBar
        :selected-run-ids="blockOutput.selectedRunIds.value"
        :is-workflow-draft="workflow?.draft ?? true"
        @rerun-selected-runs="() => blockOutput.handleRerunSelectedRuns(workflowId)"
        @download-selected-runs="blockOutput.downloadSelectedRuns"
        @delete-selected-runs="blockOutput.handleDeleteSelectedRuns"
      />
    </template>
  </MainPageLayout>

  <RunBlockConfigModal
    v-if="workflow"
    :block-config="workflowBlock?.blockConfig"
    :workflow="workflow"
    @submit="fetch"
  />
</template>

<style module>
.table {
  border-bottom: 2px solid #eee;
}
.table th,
.table td {
  padding: 8px 0;
  border-top: 2px solid #eee;
}
.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textMuted {
  color: var(--grey-4-disabled);
}
</style>
