import { useUtils } from '@/ui/composables'
import { computed } from 'vue'
import { useWorkflowDetails } from './useWorkflowDetails'

export function useEditorState() {
  const { workflow, updateWorkflow } = useWorkflowDetails()
  const { debounce } = useUtils()

  function getState(keys: string[] | string) {
    const value = getPropertyFromUnknown(workflow.value?.editorState, [keys].flat())
    return value
  }

  const editorState = computed<Record<string, unknown>>(() => {
    if (isRecord(workflow.value?.editorState)) return workflow.value.editorState
    return {}
  })

  function setState(value: unknown, ...path: string[]) {
    // Safely set a nested property on an unknown object
    const obj = editorState.value
    const lastKey = path.pop()
    if (!lastKey || !isRecord(obj)) return
    let o = obj
    for (const key of path) {
      const cur = o[key]
      const next = isRecord(cur) ? cur : {}
      o[key] = next
      o = next
    }
    o[lastKey] = value
    debouncedSave.trigger()
  }

  const debouncedSave = debounce(saveState)

  async function saveState() {
    if (!workflow.value) return
    await updateWorkflow({ editorState: editorState.value })
  }

  return {
    setState,
    getState,
  }
}

// Helper methods:

function getPropertyFromUnknown(obj: unknown, keys: string[]): unknown {
  // Safely get a nested property from an unknown object
  let o = obj
  for (const key of keys) {
    if (o === null || typeof o !== 'object') return
    o = (o as Record<string, unknown>)[key]
  }
  return o
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return value != null && typeof value === 'object' && !Array.isArray(value)
}
